<template>
  <div />
</template>

<script>
export default {
  setup() {
    const now = useNow();

    // Computed
    const newWebsocketRequests = computed(() => useNuxtApp().$store.state.operator.requests.newWebsocketRequests);

    return {
      now,
      newWebsocketRequests,
    };
  },

  data() {
    return {
      isLoading: false,
      emergencyNotifys: [],
      closedEmergencyRequestIds: [],
      audio: {
        newRequest: new Audio('/sounds/request_new.mp3'),
        emergency: new Audio('/sounds/request_new_emergency.mp3'),
        planned: new Audio('/sounds/request_plan.mp3'),
        planned2x: new Audio('/sounds/request_plan_2x.mp3'),
      },
      timeTickPrevious: null,
    };
  },

  computed: {
    lastNewWebsocketRequest() {
      return this.newWebsocketRequests
        ? this.newWebsocketRequests[this.newWebsocketRequests.length - 1]
        : undefined;
    },
    requestsEmergency() {
      return this.$store.state.operator.requests.requests.filter((request) => request.type_id === this.$store.state.requests.EMERGENCY_TYPE_ID && request.state.id === 1 && !this.closedEmergencyRequestIds.includes(request.id));
    },
    unassignedRequests() {
      return this.$store.getters['operator/requests/getDashboardRequests'].filter((request) => !request.operator);
    },
  },

  watch: {
    async newWebsocketRequests() {
      if (!this.newWebsocketRequests.length) { return }
      const lastReq = this.lastNewWebsocketRequest;
      const localStorageFilters = JSON.parse(localStorage.getItem('operatorRequestsFilter'));

      let playSound = false;

      if (localStorageFilters) {
        const stateFilterActive = localStorageFilters.states !== [1, 2, 3];
        const typeFilterActive = localStorageFilters.types && localStorageFilters.types.length;
        const serviceFilterActive = !!localStorageFilters.services;

        const isStateFiltered = localStorageFilters.states &&
          localStorageFilters.states.length &&
          localStorageFilters.states.includes(lastReq.state_id);

        const isTypeFiltered = localStorageFilters.types &&
          localStorageFilters.types.length &&
          localStorageFilters.types.includes(lastReq.type_id);

        const isServiceFiltered = localStorageFilters.services &&
          localStorageFilters.services === lastReq.service_id;

        if (stateFilterActive && typeFilterActive && serviceFilterActive) {
          // a+b+c
          if (isStateFiltered && isTypeFiltered && isServiceFiltered) { playSound = true }
        } else if (!stateFilterActive && typeFilterActive && serviceFilterActive) {
          // b+c
          if (isTypeFiltered && isServiceFiltered) { playSound = true }
        } else if (stateFilterActive && !typeFilterActive && serviceFilterActive) {
          // a+c
          if (isStateFiltered && isServiceFiltered) { playSound = true }
        } else if (stateFilterActive && typeFilterActive && !serviceFilterActive) {
          // a+b
          if (isStateFiltered && isTypeFiltered) { playSound = true }
        } else if (stateFilterActive && !typeFilterActive && !serviceFilterActive && isStateFiltered) {
          playSound = true; // a
        } else if (!stateFilterActive && typeFilterActive && !serviceFilterActive && isTypeFiltered) {
          playSound = true; // b
        } else if (!stateFilterActive && !typeFilterActive && serviceFilterActive && isServiceFiltered) {
          playSound = true; // c
        } else {
          // no filter active
          playSound = true;
        }
      } else {
        playSound = true;
      }

      if (playSound) {
        const isPlayedByExtension = await useUIStore().chromeExtensionSendMessage({ playAudio: 'newRequest' });
        if (!isPlayedByExtension) {
          this.audio.newRequest.play().catch((error) => {
            console.warn(error);
          });
        }
      }
    },

    now: {
      handler(now) {
        // on time tick check all requests and throw a message about a planned request 15 and 5 minutes ahead of time
        const nowMoment = dayjs(now).startOf('minute');

        this.unassignedRequests.filter((req) => req.has_book_time).forEach((req, index) => {
          const bookMoment = dayjs(req.book_time);

          const diff = bookMoment.diff(nowMoment, 'minute');

          if (!this.timeTickPrevious || !nowMoment.isSame(this.timeTickPrevious, 'minutes')) {
            if (diff === 5 || diff === 0) {
              setTimeout(() => {
                ElMessage.warning({
                  message: `Naplánovaný <a href="/operator/requests/${req.id}/detail">požadavek</a> za ${diff} minut.`,
                  dangerouslyUseHTMLString: true,
                  duration: 4000,
                  showClose: true,
                });
                this.audio[diff === 5 ? 'planned' : 'planned2x'].play().catch((error) => {
                  console.warn(error);
                });
              }, 10 * index);
            }
          }
        });

        this.timeTickPrevious = nowMoment;
      },
    },

    requestsEmergency: {
      deep: true,
      handler() {
        this.checkEmergency();
      },
    },

    emergencyNotifys: {
      async handler(emergencyNotifys) {
        if (emergencyNotifys && emergencyNotifys.length) {
          const isPlayedByExtension = await useUIStore().chromeExtensionSendMessage({ playAudio: 'emergency' });
          if (!isPlayedByExtension) {
            this.audio.emergency.loop = true;
            this.audio.emergency.play().catch((error) => {
              console.warn(error);
              setTimeout(() => {
                this.checkEmergency();
              }, 2 * 1000);
            });
          }
        } else {
          const isPlayedByExtension = await useUIStore().chromeExtensionSendMessage({ stopAudio: 'emergency' });
          if (!this.audio.emergency.paused && !isPlayedByExtension) {
            this.audio.emergency.pause();
          }
        }
      },
    },
  },

  mounted() {
    this.audio.emergency.addEventListener('play', (event) => console.log('▶️ AUDIO: Emergency', event));
    this.audio.newRequest.addEventListener('play', (event) => console.log('▶️ AUDIO: newRequest', event));
    this.audio.planned.addEventListener('play', (event) => console.log('▶️ AUDIO: planned', event));
    this.audio.planned2x.addEventListener('play', (event) => console.log('▶️ AUDIO: planned2x', event));
  },

  methods: {
    checkEmergency() {
      if (!this.requestsEmergency) {
        console.warn('requestsEmergency is not defined');
        return;
      }

      this.requestsEmergency.forEach((request) => {
        const isNotifyExist = this.emergencyNotifys.some(({ id }) => id === request.id);

        if (!isNotifyExist) {
          const notify = ElNotification({
            type: 'error',
            customClass: 'el-notification--danger tw-cursor-pointer',
            title: 'SOS linka',
            message: `${request.id} - ${request.client.first_name} ${request.client.last_name}`,
            duration: 0,
            offset: 80,
            onClick: () => {
              this.$router.push(this.localePath(`/operator/requests/${request.id}/detail`));
            },
            onClose: () => {
              if (!this.audio.emergency.paused) {
                this.audio.emergency.pause();
              }

              this.closedEmergencyRequestIds.push(request.id);
              this.emergencyNotifys = this.emergencyNotifys.filter(({ id }) => id !== request.id);
            },
          });

          this.emergencyNotifys.push({
            id: request.id,
            notify,
          });
        }
      });

      this.emergencyNotifys = this.emergencyNotifys.filter(({ id, notify }) => {
        const isRequestEmergencyExist = this.requestsEmergency.some((request) => request.id === id);

        if (!isRequestEmergencyExist) {
          notify.close();
        }

        return isRequestEmergencyExist;
      });
    },
  },
};
</script>
