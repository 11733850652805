<template>
  <div
    class="CallingBox"
    :style="style"
  >
    <div
      v-if="isOpen"
      class="Keypad tw-mb-3"
    >
      <div
        v-for="(key, index) in keys"
        :key="index"
        class="KeypadItem"
      >
        <el-button
          :key="index"
          size="large"
          circle
          class="tw-bg-white/20 hover:tw-bg-white/30 tw-text-white"
          @click="handleNumberClicked(key)"
        >
          {{ key }}
        </el-button>
      </div>
    </div>
    <div class="tw-grid tw-grid-cols-12">
      <div class="tw-col-span-7">
        <strong v-if="isConnected">
          {{ timerCounter }}
        </strong>
        <strong v-else>
          Vyzvánění…
        </strong>
        <div class="color-muted">
          {{ useOperatorCallingStore().number }}
        </div>
      </div>
      <div class="tw-col-span-5 tw-flex tw-justify-end">
        <el-button
          circle
          class="tw-bg-white/20 hover:tw-bg-white/30 tw-text-white"
          @click="isOpen = !isOpen"
        >
          <component :is="`icon-${isOpen ? 'arrow-fill-down' : 'keypad'}`" />
        </el-button>
        <el-button
          type="danger"
          circle
          @click="handleCancelCall"
        >
          <icon-phone-cancel />
        </el-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Device } from '@twilio/voice-sdk';

// Refs
const isOnline = useOnline();
const el = useCurrentElement();
const { style } = useDraggable(el, {
  initialValue: { },
});

const isOpen = ref(false);
const isConnected = ref(false);
const device = ref(null);
const keys = ref([1, 2, 3, 4, 5, 6, 7, 8, 9, '*', 0, '#']);
const connection = ref(null);
const intervalTimer = ref(null);
const timerDuration = ref(null);

// Computed
const timerCounter = computed(() => {
  if (!timerDuration.value) { return '00:00:00' }
  const time = dayjs.utc(timerDuration.value.asMilliseconds());
  return time.format('HH:mm:ss');
});

// Methods
const showUniversalErrorMessage = _debounce(() => {
  ElMessage.error({
    message: 'Došlo k neočekávanému výpadku spojení telefonního hovoru.',
    duration: 4000,
    showClose: true,
  });
}, 500);

const handleConnectionError = (error) => {
  console.warn('[CallingBox] Connection Error:', error.message);

  if (error && error.code !== 31009) { // 31009 => offline
    console.error('[CallingBox]', error);

    if (error.message.includes('JWT') || error.message.includes('transport')) {
      // 'Telefonní hovor expiroval, načtete prosím aplikaci znovu.';
      return;
    }

    if (error.code === 31003 || error.code === 31005 || error.code === 31000 || error.code === 31204) { // list of codes: https://www.twilio.com/docs/voice/sdks/error-codes
      if (!this.isConnected || !isOnline.value) {
        return;
      }

      showUniversalErrorMessage();

      return;
    }

    ElMessage.error({
      message: `Došlo k chybě ${error.message}`,
      duration: 4000,
      showClose: true,
    });
  }
};

const disconnected = () => {
  connection.value = null;
  device.value = null;
  useOperatorCallingStore().cancel();
};

const connect = async() => {
  if (device.value) {
    connection.value = await toRaw(device.value).connect({
      params: {
        To: useOperatorCallingStore().number,
        hash: useOperatorCallingStore().hash,
      },
    });

    connection.value.on('accept', (payload) => {
      console.log('[CallingBox] Connection accepted', payload);

      isConnected.value = true;

      timerDuration.value = dayjs.duration(0);
      clearInterval(intervalTimer.value);
      intervalTimer.value = setInterval(() => {
        timerDuration.value = timerDuration.value.add(1, 'second');
      }, 1000);
    });

    connection.value.on('cancel', (payload) => {
      console.log('[CallingBox] Connection canceled', payload);
    });

    connection.value.on('disconnect', (payload) => {
      console.log('[CallingBox] Connection disconnected', payload);

      disconnected();
    });

    connection.value.on('error', (error) => {
      console.warn('[CallingBox] Connection error:', error);
      handleConnectionError(error);
    });

    connection.value.on('reject', (payload) => {
      console.log('[CallingBox] Connection rejected', payload);
    });

    connection.value.on('ringing', () => {
      console.log('[CallingBox] Connection ringing');
    });

    connection.value.on('warning', (payload) => {
      console.log('[CallingBox] Connection warning', payload);
    });
  } else {
    console.warn('[CallingBox] Device Error: Device is undefined');
  }
};

const handleNumberClicked = (key) => {
  if (connection.value) {
    connection.value.sendDigits(key.toString());
  }
};

const handleCancelCall = () => {
  if (device.value) {
    toRaw(device.value).disconnectAll();
  }
  disconnected();
};

// Lifecycle
onMounted(async() => {
  isConnected.value = false;

  device.value = toRaw(new Device(toRaw(useOperatorCallingStore().token), {
    logLevel: 'ERROR', // 'TRACE', 'DEBUG', 'INFO', 'WARN', 'ERROR', 'SILENT'
  }));

  device.value.on('registered', () => {
    console.log('[CallingBox] Device registered');
    connect();
  });

  device.value.on('unregistered', (payload) => {
    console.log('[CallingBox] Device unregistered', payload);
  });

  device.value.on('destroyed', (payload) => {
    console.log('[CallingBox] Device destroyed', payload);
  });

  await device.value.register();
});
</script>

<style scoped>
.CallingBox {
  position: fixed;
  right: 20px;
  left: auto;
  top: calc(72px + 15px); /* header height + offset */
  z-index: 12345;
  @apply tw-bg-dark tw-bg-opacity-90;
  @apply tw-text-white;
  border-radius: 4px;
  box-shadow: 0 20px 50px rgb(0 0 0 / 0.1);

  padding: 18px;
  width: 270px;

  line-height: 1.4;
}

.Keypad {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .KeypadItem {
    width: 48px;
    margin-bottom: 12px;
    margin-left: 6px;
    margin-right: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
